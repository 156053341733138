
// The dateFormat filter is a layer between the angular default
// filter to allow us to parse all dates first as well as to give
// a more obvious error state than angulars default.

// When errors happen the output will include Undefined and NaN
// instead of a string format of the date. This usually happens
// when you use one time binding on a value before it gets the
// final value it needs.


angular.module('BillPay').filter('dateFormat', function($filter, moment, _){
    return function(date, format){

        if(_.isUndefined(date) || date === null){
            return '';
        }

        if(_.isUndefined(format) || format === null) {
            format = 'll';
        }

        return moment(date).format(format);
    };
});