angular.module('BillPay').directive('allNumeric', function(_, KeyboardService){


    // this directive is used as a fast way to apply the only numbers
    // allowed rule to an input that doesnt need complex logic -- like zip

    return {
        link: function(scope, el){

            // only accept numbers
            el.on('keydown', function(event){
                var key = KeyboardService.parseEvent(event);

                // we have no reason to use shift currently, so instead of
                // trying to filter those out on keyup, prevent them now
                if((!key.isNumber() && !key.isEditorKey() && !key.hasModifier()) || key.is('shift')){
                    event.preventDefault();
                }
            });

            el.on('keyup', function(){

                var start = this.selectionStart,
                    end = this.selectionEnd,
                    atEnd = this.selectionStart === el.val().length,
                    newVal = el.val().replace(/(\s|\D)/g, '');

                el.val(newVal);

                if(atEnd){
                    this.setSelectionRange(newVal.length, newVal.length);
                }else {
                    this.setSelectionRange(start, end);
                }
            });
        }
    };
});