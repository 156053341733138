angular.module('BillPay').directive('compile', function($compile) {

    return {
        restrict: 'A',
        link: function($scope, element, attrs) {

            var compileHtml = function () {
                var value = $scope.$eval(attrs.compile);
                element.html(value);
                $compile(element.contents())($scope);
            };

            compileHtml();
            $scope.$watch(attrs.compile, compileHtml);
        }
    };
});
