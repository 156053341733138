angular.module('BillPay')
    .directive('loadingIndicator', function($rootScope, $timeout) {

        return {
            restrict: 'E',
            replace: true,
            template: '<div class="loading-indicator ng-hide"></div>',
            link: function($scope, element) {

                var stateLoaded,
                    triggerLoadingState = function () {
                        stateLoaded = false;
                        $timeout(function () {
                            if(!stateLoaded)
                                element.removeClass('ng-hide');
                        }, 0);
                    },
                    endLoadingState = function () {
                        stateLoaded = true;
                        $timeout(function () {
                            if(stateLoaded)
                                element.addClass('ng-hide');
                        }, 0);
                    };

                $rootScope.$on('$stateChangeStart', triggerLoadingState);
                $rootScope.$on('$stateChangeSuccess', endLoadingState);
                $rootScope.$on('$stateChangeRejected', endLoadingState);

                $rootScope.$on('showLoadingIndicator', triggerLoadingState);
                $rootScope.$on('hideLoadingIndicator', endLoadingState);
            }
        };
    })

    .directive('onLoad', function($rootScope, $timeout) {

        return {
            restrict: 'A',
            link: function($scope, element) {

                var triggerLoadingState = function () {
                        element.addClass('ng-hide');
                    },
                    endLoadingState = function () {
                        element.removeClass('ng-hide');                   
                    };

                $rootScope.$on('$stateChangeStart', triggerLoadingState);
                $rootScope.$on('$stateChangeSuccess', endLoadingState);
                $rootScope.$on('$stateChangeRejected', endLoadingState);

                $rootScope.$on('showLoadingIndicator', triggerLoadingState);
                $rootScope.$on('hideLoadingIndicator', endLoadingState);
            }
        };
    });
