angular.module('BillPay')
    .controller('LandingLinkCtrl', function ($scope, $rootScope, $filter, AppStates,
                                        $translate, ProviderPaymentService, Provider) {

        $scope.Provider = Provider;

        $translate(['meta.title', 'meta.description'], {'providerName': Provider.name}).then(function (translations) {
            $rootScope.metaTitle = translations['meta.title'];
            $rootScope.metaDescription = translations['meta.description'];
        });

        $scope.marketingBlurb = $filter('translate')('landing.marketingBlurb', {
            providerName: Provider.name
        });
    });
