/*
    a standardized way of turning a noun into its possessive form
*/

angular.module('BillPay').filter('possessive', function(){
    return function (noun) {
        if (!noun) {
            return noun;
        }
        return (noun.charAt(noun.length-1).toLowerCase() === 's') ? noun + '\'' : noun + '\'s';
    };
});