angular.module('BillPay').directive('phoneNumber', function($timeout, _, KeyboardService){

    var _MAX_FORMATTED_LENGTH = 12,

        _getUnformattedValue = function(val){
            return (val || '').toString().replace(/(\s|\D)/g, '');
        },

        _getFormattedString = function(inputStr){
            var noFormatting = _getUnformattedValue(inputStr),
                i = 0,
                curChar = '',
                output = '';

            while(i < noFormatting.length){

                curChar = noFormatting[i];

                if(/[0-9]/.test(curChar)){
                    output += curChar;
                }

                i++;

                if(_.includes([3, 6], i)){
                    output += '-';
                }
            }

            return output.slice(0, _MAX_FORMATTED_LENGTH);
        };

    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ngModel){

            var runValidators = function(value){
                    var noFormatValue = _getUnformattedValue(value);
                    ngModel.$setValidity('minlength', noFormatValue.length > 0 ? noFormatValue.length >= 10 : true);

                },
                validKey = function(keyCode){
                    return /[a-zA-Z0-9-]/.test(String.fromCharCode(keyCode));
                };



            // don't allow more than the formatted length
            el.attr('maxlength', _MAX_FORMATTED_LENGTH);

            el.on('paste', function(){
                $timeout(function(){
                    el.val(_getFormattedString(el.val()));
                }, 50);
            });

            // only accept numbers
            el.on('keydown', function(event){

                var key = KeyboardService.parseEvent(event);

                // we have no reason to use shift currently, so instead of
                // trying to filter those out on keyup, prevent them now
                if((!key.isNumber() && !key.isEditorKey() && !key.hasModifier()) || key.is('shift')){
                    event.preventDefault();
                }
            });


            el.on('keyup', function(event){
                var key = event.which || event.keyCode || event.charCode,
                    start = this.selectionStart,
                    end = this.selectionEnd,
                    val = el.val(),
                    atEnd = this.selectionStart === val.length;


                // key sent when browser is processing the event
                // we will pull the character out and get the code directly
                if(key === 229 && val.length > 0 ){
                    key = val.charCodeAt(val.length - 1);
                }

                // if we are deleting, we shouldn't worry about anything
                if(!validKey(key)){
                    return;
                }

                el.val(_getFormattedString(el.val()));

                if(!atEnd){
                    this.setSelectionRange(start, end);
                }
            });

            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function (newValue) {
                runValidators(newValue);
            });


            ngModel.$formatters.push(function(newInput){
                return _getFormattedString(newInput || '');
            });

            ngModel.$parsers.push(function(viewValue){
                return _getFormattedString(viewValue || '');
            });
        }
    };

});