angular.module('BillPay')
    .config(function ($stateProvider, AppStatesProvider, AppPathsProvider) {

        var states = AppStatesProvider.get(),
            paths = AppPathsProvider.get();

        $stateProvider
            .state(states.link.root, {
                url: 'link/',
                templateUrl: paths.link.root,
                controller: 'LandingLinkCtrl',
                abstract: true,
                resolve: {
                    Provider: function (ProvidersService) {
                        return ProvidersService.getProvider();
                    }
                },
                onEnter: function ($rootScope) {
                    $rootScope.showNavigation(false);
                }
            })
            .state(states.link.init, {
                url: '',
                templateUrl: paths.link.init,
                controller: 'LinkCtrl',
                resolve: {}
            })
            .state(states.link.token, {
                url: ':token/',
                controller: 'LinkTokenCtrl',
                resolve: {}
            });
    });
