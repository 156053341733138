angular.module('BillPay')
    .factory('Cache', function ($log) {

        var logger = $log.getInstance('cache');

        function Cache (hashPrefix, cacheDeep) {
            this._cache = {};
            this.hashPrefix = hashPrefix ? hashPrefix : 'billpay';
            this.cacheDeep = !!cacheDeep;
        }

        Cache.prototype.hashKey = function (str) {
            return this.hashPrefix + (str ? ['.' + str ]: '');
        };

        Cache.prototype.add = function (value, key) {
            key = this.hashKey(key);

            if (angular.isDefined(this._cache[key])) {
                logger.debug('Updating \'' + key + '\' in local cache.');
            } else {
                logger.debug('Cached \'' + key + '\' in local cache.');
            }

            this._cache[key] = value;

            if (this.cacheDeep) {
                if (angular.isString(key)) {
                    var data = angular.isFunction(value.serialize) ?
                                value.serialize() : value;

                    if (sessionStorage.getItem(key)) {
                        logger.debug('Updating \'' + key + '\' in session cache.');
                    } else {
                        logger.debug('Cached \'' + key + '\' in session.');
                    }

                    sessionStorage.setItem(key, angular.toJson(data));
                }
            }
        };

        Cache.prototype.delete = function (key) {
            key = this.hashKey(key);

            delete this._cache[key];

            if (this.cacheDeep) {
                sessionStorage.removeItem(this.hashPrefix + key);
            }
        };

        Cache.prototype.get = function (key) {
            key = this.hashKey(key);

            var cachedData = this._cache[key];

            if (angular.isDefined(cachedData)) {
                logger.debug('Retrieved \'' + key + '\' from local cache.');
                return cachedData;
            } else if (this.cacheDeep) {
                cachedData = sessionStorage.getItem(key);
                if (cachedData !== null) {
                    logger.debug('Retrieved \'' + key + '\' from session cache.');
                    return angular.fromJson(cachedData);
                }
            }

            logger.warn('Could not find \'' + key + '\' in any cache.');
            return;
        };

        return Cache;
    });
