angular.module('BillPay')
    .constant('PAYMENT_STATE', {
        UNCONFIRMED: 'UNCONFIRMED',
        ABANDONED: 'ABANDONED',
        CANCELLED: 'CANCELLED',
        SCHEDULED: 'SCHEDULED',
        HELD: 'HELD',
        DECLINED: 'DECLINED',
        REPROCESSED: 'REPROCESSED',
        PROCESSED: 'PROCESSED',
        VOIDED: 'VOIDED',
        UNDISBURSED: 'UNDISBURSED',
        DISBURSED: 'DISBURSED',
        UNKNOWN: 'UNKNOWN'
    })
    .constant('PAYMENT_METHOD', {
        'ECHECK': 'echeck',
        'CREDIT': 'card'
    })
    .constant('ACCEPTED_CREDIT', [
        'Mastercard', 'Visa', 'American Express', 'Discover'
    ]);
