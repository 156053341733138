angular.module('BillPay').directive('providerLogo', function($window, endpoints){
    return {
        restrict : 'E',
        scope: {
            urlSlug: '=',
            altText: '='
        },
        replace: true,
        link: function($scope, el, attrs){
            $scope.$watch('urlSlug', function(urlSlug){
                if(angular.isDefined(urlSlug)){
                    //TODO verify with backend
                    var logoUrl = endpoints.providers.logo.url;
                    logoUrl = logoUrl.replace(':urlSlug', urlSlug);
                    $scope.providerLogo = '<img src="' + logoUrl + '" alt="' + attrs.altText + '"/>';
                }
            });
        },
        template: '<div class="provider-logo" ng-bind-html="providerLogo"></div>'
    };
});
