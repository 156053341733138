angular.module('BillPay')
    .service('ProviderPaymentService', function (Cache, PaymentModel, ProvidersService,
                                                 $log, $q, PAYMENT_METHOD, Compass,
                                                 ErrorsService, PaymentGatewayService, moment) {

        var _cache = new Cache('provider-payment'),
            logger = $log.getInstance('provider-payment');

        this.createProviderPaymentFromBill = function (accountNumber, amountDue) {
            var defer = $q.defer();

            ProvidersService.getProvider().then(
                function (provider) {
                    var payment = new PaymentModel(),
                        regEx = new RegExp(provider.accountNumberRegex);
                    if (regEx.test(accountNumber)
                        && amountDue >= provider.limits.minimumPaymentAmount
                        && payment.set('providerSlug', provider.urlSlug)
                        && payment.set('accountNumber', accountNumber)
                        && payment.set('amountDue', amountDue)) {
                        _cache.add(payment, provider.urlSlug);
                        defer.resolve(payment);
                    } else {
                        defer.reject();
                    }
                },
                function (error) {
                    logger.error('No provider found to create payment.');
                    defer.reject();
                }
            );

            return defer.promise;
        };

        this.getCurrentProviderPayment = function () {
            var defer = $q.defer();

            ProvidersService.getProvider().then(
                function (provider) {
                    var paymentData = _cache.get(provider.urlSlug), payment = {};
                    if (angular.isUndefined(paymentData)) {
                        defer.reject({'status': 404, 'statusText': 'No Payment Found', 'errorCode': 'NOT_FOUND'});
                    } else {
                        payment = paymentData instanceof PaymentModel ? paymentData : new PaymentModel(paymentData);
                        defer.resolve(payment);
                    }
                },
                function (error) {
                    logger.error('No provider found to retrieve payment.');
                    defer.reject({'status': 404, 'statusText': 'No Provider Found', 'errorCode': 'NOT_FOUND'});
                }
            );

            return defer.promise;
        };

        this.updatePayment = function (payment) {
            if (angular.isDefined(payment) && angular.isString(payment.providerSlug)) {
                _cache.add(payment, payment.providerSlug);
            } else {
                logger.warn('Provider payments must have provider slug to be cached.');
            }
        };

        this.validateProviderPaymentAmount = function (paymentAmount) {
            var defer = $q.defer();

            if (!angular.isNumber(paymentAmount)) {
                defer.resolve(false);
            } else {
                ProvidersService.getProvider().then(
                    function (provider) {
                        var isValid = (angular.isUndefined(provider.limits.minimumPaymentAmount) ||
                                        paymentAmount >= provider.limits.minimumPaymentAmount)
                                        && (angular.isUndefined(provider.limits.maximumPaymentAmount) ||
                                        paymentAmount <= provider.limits.maximumPaymentAmount);
                        defer.resolve(isValid);
                    },
                    function (error) {
                        logger.error('No provider found to validate payment amount.');
                        defer.reject();
                    }
                );

            }

            return defer.promise;
        };

        this.validateProviderPaymentDate = function (paymentDate) {
            var defer = $q.defer();

            if (angular.isUndefined(paymentDate)) {
                defer.resolve(false);
            } else {
                paymentDate = moment(paymentDate);
                if (angular.isDefined(paymentDate) && paymentDate.isValid()) {
                    ProvidersService.getProvider().then(
                        function (provider) {
                            var scheduleDays = paymentDate.diff(moment(), 'days');
                            if (angular.isDefined(provider.limits.maxScheduleDays)) {
                                defer.resolve(scheduleDays >= 0 && scheduleDays <= provider.limits.maxScheduleDays);
                            } else {
                                defer.resolve(scheduleDays >= 0);
                            }
                        },
                        function (error) {
                            logger.error('No provider found to validate payment date.');
                            defer.reject();
                        }
                    );
                } else {
                    defer.resolve(false);
                }
            }

            return defer.promise;
        };

        this.validateProviderPaymentMethod = function (paymentMethod) {
            var defer = $q.defer();

            ProvidersService.getProvider().then(
                function (provider) {
                    defer.resolve(provider.allowsPaymentType(paymentMethod));
                },
                function (error) {
                    logger.error('No provider found to validate payment method.');
                    defer.reject();
                }
            );

            return defer.promise;
        };

        this.processProviderPayment = function (payment, recaptchaResponse, isRetry) {
            var defer = $q.defer(),
                processPath;

            if (payment.isValid()) {
                if (isRetry && !payment.isDirty()) {
                    logger.debug('Processing payment as retry.');
                    processPath = PaymentGatewayService.resume;
                } else {
                    logger.debug('Processing payment as new payment.');
                    payment.isDirty(false);
                    processPath = PaymentGatewayService.process;
                }
            }

            processPath(payment, recaptchaResponse)
                .then(function (payment) {
                    Compass.capture.success('payment', 'processed');
                    payment = new PaymentModel(payment);
                    payment.set('completed', true);
                    _cache.delete(payment.providerSlug);
                    defer.resolve(payment);
                })
                .catch(function (resp) {
                    var error = ErrorsService.resolve(resp);
                    logger.error('Processing error', resp, error);
                    Compass.capture.failure('payment', 'processed');
                    Compass.capture.event('payment', 'failureReason', error || 'NO_REASON_AVAILABLE');
                    defer.reject(error);
                });


            return defer.promise;
        };

    });
