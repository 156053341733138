angular.module('BillPay')
    .directive('bpFocus', function($timeout) {
        return {
            restrict : 'A',
            scope: false,
            link: function ($scope, element, attrs) {
                $scope.$watch(attrs.bpFocus, function (value) {
                    /*eslint no-extra-boolean-cast: 0*/
                    if(!!value) {
                        $timeout(function() {
                            element[0].focus();
                        });
                    }
                });
            }
        };
    });
