angular.module('BillPay')
    .controller('PaymentAddressCtrl', function ($scope, $filter, Payment) {

        $scope.streetAddress = Payment.streetAddress;
        $scope.zip = Payment.zip;

        $scope.billingAddressQuestion = $filter('translate')('payment.billing.question', {
            methodSummary : Payment.paymentForm.toShortString()
        });

        $scope.setAddress = function (streetAddress, zip) {
            if ($scope.forms.billing.$invalid) return;

            if (Payment.set('streetAddress', streetAddress) && Payment.set('zip', zip)) {
                $scope.continue();
            }
        };
    });
