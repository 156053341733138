angular.module('BillPay').factory('NavService', function($rootScope, resize, OverlayService){

    var service = {

        open: function(){

            this.isOpen = true;

            // needed for show click closure
            var that = this;
            OverlayService.show('nav_overlay', function(){
                that.close();
            });
        },

        close: function(){
            if(this.isOpen){
                this.isOpen = false;
                OverlayService.hide('nav_overlay');
            }
        },

        toggle: function(){
            return this.isOpen ? this.close() : this.open();
        },

        isOpen: false
    };


    // We could run into an issue where positioning of the layout
    // is incorrect if we are on a desktop view with the nav open
    // and someone resizes the browser wider. We will listen for that
    // resize event on root to give us the trigger to close
    $rootScope.$on('resize', function(){
        service.close();
    });

    return service;
});