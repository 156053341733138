angular.module('BillPay')
    .controller('PaymentAmountCtrl', function ($scope, $filter, Payment, Provider, ProviderPaymentService) {

        $scope.continueDialog = $filter('translate')('actions.continue');

        $scope.translationData.amountDue = $filter('currency')(Payment.amountDue);
        $scope.translationData.minAmount = $filter('currency')(Provider.limits.minimumPaymentAmount);
        $scope.translationData.maxAmount = $filter('currency')(Provider.limits.maximumPaymentAmount);

        $scope.overpaying = false;
        $scope.paymentAmount = Payment.amount;
        $scope.togglePaymentForm = Payment.amountDue > Provider.limits.maximumPaymentAmount;

        var toggleWarningMessage = function (bool) {
            $scope.overpaying = bool;
            $scope.continueDialog = bool ? $filter('translate')('actions.continueAnyway') : $filter('translate')('actions.continue');
        };

        $scope.showPaymentAmountForm = function () {
            $scope.togglePaymentForm = true;
        };

        $scope.updateWarningMessage = function (amount) {
            if ($scope.overpaying && amount <= Payment.amountDue) {
                toggleWarningMessage(false);
            }
        };

        $scope.setPaymentAmount = function (amount) {

            if (angular.isUndefined(amount) && $scope.togglePaymentForm) {
                return;
            }

            if (!$scope.overpaying && amount > Payment.amountDue) {
                toggleWarningMessage(true);
            } else {
                toggleWarningMessage(false);

                amount = amount || Payment.amountDue;
                ProviderPaymentService.validateProviderPaymentAmount(amount).then(
                    function (isValid) {
                        if (isValid && Payment.set('amount', amount)) {
                            $scope.continue(function () {
                                $scope.togglePaymentForm = !Payment.payingAmountDue;
                            });
                        }
                    }
                );
            }
        };
    });
