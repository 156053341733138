angular.module('BillPay').directive('patientcoLogo', function(){

    return {
        scope: false,
        link: function(){},
        template:   '<svg id="patientco_logo_1" data-name="Patientco Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.46 107.46">'+
                        '<defs>'+
                            '<style>'+
                                'svg#patientco_logo_1 .cls-1 {'+
                                    'fill:#9e9e9e;'+
                                '}'+
                                'svg#patientco_logo_1 .cls-2 {'+
                                    'fill:#9e9e9e;'+
                                '}'+
                                'body.guest-pay svg#patientco_logo_1 .cls-1 {'+
                                    'fill:#10356b;'+
                                '}'+
                                'body.guest-pay svg#patientco_logo_1 .cls-2 {'+
                                    'fill:#1dab51;'+
                                '}'+
                                'svg#patientco_logo_1 .cls-2 {'+
                                    'fill-rule:evenodd;'+
                                '}'+
                            '</style>'+
                        '</defs>'+
                        '<title>Patientco Logo</title>'+
                        '<path class="cls-1" d="M147.32,39.43v66.7" transform="translate(-20.07 -18.67)"/>'+
                        '<path class="cls-1" d="M73.8,31.62A40.9,40.9,0,0,0,33,72.4v53.73H20.07V72.4A53.73,53.73,0,1,1,73.8,126.13a53.33,53.33,0,0,1-27.49-7.63v-16A40.77,40.77,0,1,0,73.8,31.62Z" transform="translate(-20.07 -18.67)"/>'+
                        '<path class="cls-2" d="M73.8,44.91h0A27.57,27.57,0,0,0,46.31,72.4h0A27.57,27.57,0,0,0,73.8,99.89h0A27.57,27.57,0,0,0,101.29,72.4h0A27.57,27.57,0,0,0,73.8,44.91Zm6.47,45.22H67.33V78.87H56.07V65.93H67.33V54.67H80.27V65.93H91.53V78.87H80.27Z" transform="translate(-20.07 -18.67)"/>'+
                    '</svg>'
    };
});