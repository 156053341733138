
angular.module('BillPay').factory('CardService', function(_, $filter){

    return {
        getIssuer : function( number ){

            var issuer,

                // see if the first part of a card is within an issuers
                // alloted range. NOTE: the upperBound is non inclusive
                // so if your range is between 1-5 -> 5 will not be true
                // so go one digit higher than your max value
                numberInRange = function(number, lowerBound, upperBound){
                    number = number.toString().slice(0, upperBound.toString().length);
                    return _.inRange(_.parseInt(number), lowerBound, upperBound);
                };

            // trim out white space
            number = number ? _.isString(number) ? number.replace(/ /g,'') : number : '';

            if (/^5[1-5]/.test(number) || numberInRange(number, 222100, 272100) ) {
                issuer = 'Mastercard';

            } else if (/^4/.test(number)) {
                issuer = 'Visa';

            } else if (/^3[47]/.test(number)) {
                issuer = 'American Express';

            } else if (/^6(?:011|5[0-9]{2})/.test(number) || numberInRange(number, 644, 650) || numberInRange(number, 622126, 622926)) {
                issuer = 'Discover';
            }

            return issuer;
        },

        //translate the cartType enum values we get from our db enum.  All supported card types (except
        //for amex) need only to be capitalized; amex needs a space.
        getIssuerFromCardType: function(cardType) {
            var issuer = null;
            switch(cardType.toLowerCase()) {
                case 'americanexpress':
                    issuer = 'american express';
                    break;
                default:
                    issuer = cardType.toLowerCase();
                    break;
            }
            return $filter('capitalize')(issuer);
        },

        getIcon: function(issuer){
            var icon = '';
            switch((issuer || '').toLowerCase()){
                case 'mastercard':
                    icon = 'mastercard';
                    break;
                case 'visa':
                    icon = 'visa';
                    break;
                case 'american express':
                    icon = 'amex';
                    break;
                case 'discover':
                    icon = 'discover';
                    break;
                default:
                    icon = 'credit-card';
                    break;
            }
            return icon;
        },

        isExpired: function(expDate){
            var expired = false,
                expiry,
                today,
                year,
                month;

            expDate = (expDate || '').toString();

            if(expDate.length === 4){

                month = _.parseInt(expDate.slice(0, 2));
                year = _.parseInt(expDate.slice(-2));

                // do some fuzzy logic to pull out the
                // approximate year they user is referring to
                // not this is only used for expDateidations
                if (year < 70) {
                    year = '20' + (year > 9 ? year : '0' + year);
                } else {
                    year = '19' + (year > 9 ? year : '0' + year);
                }

                expiry = new Date(year, month);
                today = new Date();

                // account for zero based index
                // and roll the month up to the next month + 1 day
                // as cards expire when the month listed elapses
                expiry.setMonth(expiry.getMonth() - 1);
                expiry.setMonth(expiry.getMonth() + 1, 1);

                expired = expiry < today;
            }

            return expired;
        }
    };
});
