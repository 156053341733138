// ServerStatusService is where we keep track of server state.  At the moment, this includes which payment forms are currently process-able

angular.module('BillPay').factory('ServerStatusService', function($log, _){

    var _eCheckProcessingAvailable = true,
        _cardProcessingAvailable = true;

    return {
        updatePaymentStatus: function( availableMethods ) {
            _eCheckProcessingAvailable = _.includes(availableMethods, 'echeck');
            _cardProcessingAvailable = _.includes(availableMethods, 'card');
        },
        getPaymentStatus: function( paymentType ) {
            switch (_.trim(paymentType).toLowerCase()) {
                case 'card':
                    return _cardProcessingAvailable;
                case 'echeck':
                    return _eCheckProcessingAvailable;
                default:
                    $log.error('Invalid payment type: ' + paymentType);
                    return false;
            }
        }
    };
});