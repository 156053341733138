angular.module('BillPay')
    .factory('ProviderModel', function (CoreModel, PAYMENT_METHOD, $log) {

        return CoreModel('provider', {

            deserialize: function (data) {
                this.urlSlug = data.urlSlug;
                this.patientWalletUrl = data.patientWalletUrl;
                this.name = data.name.trim();
                this.primaryColor = data.primaryColor;
                this.secondaryColor = data.secondaryColor;
                this.accountNumberRegex = data.accountNumberRegex || '.*';
                this.accountNumberErrorMessage = data.accountNumberErrorMessage ? data.accountNumberErrorMessage.trim() : '';
                this.limits = {
                    paymentMaxScheduleDays: data.paymentMaxScheduleDays ? data.paymentMaxScheduleDays : 42,
                    minimumPaymentAmount: data.minimumPaymentAmount ? data.minimumPaymentAmount : 0.01,
                    maximumPaymentAmount: data.maximumPaymentAmount ? data.maximumPaymentAmount : 1500,
                    methods: [PAYMENT_METHOD.CREDIT]
                };

                if (data.supportsEchecks) {
                    this.limits.methods.push(PAYMENT_METHOD.ECHECK);
                }
            },

            serialize: function () {
                /*eslint no-unused-vars: [0, { "vars": "local" }]*/
                var toReturn = {
                    urlSlug: this.urlSlug,
                    patientWalletUrl: this.patientWalletUrl,
                    name: this.name,
                    primaryColor: this.primaryColor,
                    secondaryColor: this.secondaryColor,
                    accountNumberRegex: this.accountNumberRegex,
                    accountNumberErrorMessage: this.accountNumberErrorMessage,
                    paymentMaxScheduleDays: this.limits.paymentMaxScheduleDays,
                    minimumPaymentAmount: this.limits.minimumPaymentAmount,
                    maximumPaymentAmount: this.limits.maximumPaymentAmount,
                    supportsEchecks: !!~this.limits.methods.indexOf(PAYMENT_METHOD.ECHECK)
                };
            },

            allowsPaymentType: function (paymentType) {
                return !!~this.limits.methods.indexOf(paymentType);
            }

        });

    });
