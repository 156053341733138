
/*

thenthative

:``````````````````````````````.,'+++++'''++++'''++;'';''+;:;,.``````````````````````````````````````````````````````
:`````````````````````````````.'+++++++++++++++++++'+'';;';;;:,,..```````````````````````````````````````````````````
:````````````````````````````.:+##+############+++++''''';;;;;:::,,``````````````````````````````````````````````````
:````````````````````````````:'+++++++#++++++++##+++''';'';;;;;;::,,.``````````````````````````````````````````....``
:```````````````````````````,'+++++++++++++++++++++++';';';;';':;:::::,``````````````````````.`.....`.````...........
:``````````````````````````.;'+###+++++++####++++#+++';';;;;''';;;;:;::,``.````````````````..........................
:``````````````````````````:++##+++##+###+++###+++#+#+';;;;''+'';;;;;;:;..``.```````````.......,............,,,,,,,,,
:`````````````````````````,'+########+++++++++++##+####;'';+'+';;;;;;;;;.`...`````````......,,,,,,,,,,,,...,,,,,,,,,,
:`````````````````````````''###@##++++++++'+''++''''++++''';'++';;;'++':,.`.`````````......,,,,:::,,,,,,,,,,::::::::,
:```````````````````````..+'#@###+++++#++'++'++'+''''+++++;';;'';'''''';,,...``.........,,,,,::::::::::,,,,::::::::::
:``````````````````````..:++####++++++++++++++'+'+++++'+'+''+'+'''''''';:,,.`.........,,,,,:::;;;;;:::::::::::;;;;;;:
:````````````````````...:+++##++++++++++'++++'+'''''';'';;;;;;;'''+'';;;;:,......,,,,,,,,:::;;;;;;;;;::::::;;;;;;;;;;
:```````````````````....''++++++#+#+++++++++''+'''''''';';++''+''''';::;:;,,..,,,,,,,:::::;;;';;''';;;;;;;;;;;;;';;;;
:``````````````````....:'++++++###++++'++'++'+''''';';;;;';;:;'++++++':::;::.,,,,:::::::;;;''''';''';;;;;;;;;;;'''';;
:`````````````````....,'+++######+#+++'+'''+''''''''''+++'';;:::;++''+'::;;::,,:::;;;;;;;'''''''''''';;;'';''''''''''
:```````````````....,,,+#####@#####++''+'''''''''''''''''';';;'+''+';;';::;::,::;;;;'''''''''''''''''''';''''''''''''
:``````````````.....,,,+####@###+#+++''''''+'''''''''''';'';:;''+'';'';;::;:;::;'''''''''''''''''''''''''''''''''''''
:``````````````...,,,,;+###@#####+++'''+'''+'''''''''''''';;;:;''+''':;;;:;:';;''''''''''''''''''''''''''''''''''''''
:````````````.....,,::++#####+##++++'''+''++'+'''''''''';;;::::;''';'':;;::::;;''''''''''''''''''''''''''''''''''''''
:``.`.``.```.....,,:::+######+#+++++'''+''++''''''''''';';:::::;;''';;;;;::::,;'''''''''''''''+''''''''''''''''''''''
:`..............,,,:;'##@####+##+++'''''+'+''+'''''''';;';:::::;;'''':';;;::;::''''''''''''''''''+''''''++++'''''''''
;..............,,,::;;#######+##+++'''''+'''++''+'''';;;;;;:::::;;''';''';:;::;'''''''''';'''''''++++'''++++'''''''''
;.....,,,...,,,,,::;;'+#####+##+++'''''''+''++'''''''';';;::::::;;'''';';;::::''''''';;;;;;'''''++++++++++++'''''''''
;....,,,,,,,,,,,:::;;'+#@@##+#+++++'''''+''''''''''''';'';::::::;;'''';';;;:::'''''';;;;;;;;'''''+++++++++++++'''''''
;,,,,,,:::,:::::::;;;+#######+#+++++'''''+'+'''''''''';';;;;::::;;'''';';;;;:''''''';;;;;;;;'''''++++++++++++++''''''
;,,,,,:::::::::;;;;;'##+#@####+++++''''''''+'''''';''''';;;;::::;;';'';'';;;:''''''';;;;;;;;'''''+++++++++++++++'''''
':,,::;;;;;;;;;;;;'''#'''#####++++++'''''''++''''';''''';;;:::;;;;;;'';;;;;:;'''''''';;;;;;;'''''++++++++++++++++++++
'::::;;''';;;;;;'''''#'+''#@####++''''''''''++''''';'''';;;;::;;;;;;''';;;;''+''''''';;;;;;'''''+++++++++++++++++++++
':::;;;''''''''''''''++#+'+####++'''''''''''+'+'''';;;'';::::;:;;;;';;'';;''''+'''''''''';;;'''++++++++++++++++++++++
';;;;;'''+++''''''+''+++'''+###++'''''''''''''+'''';;;'';;;:::;;;;';''';''''+'+++'''''''';''''+++++++++++++++++++++++
+;;;;'''++++++++++++'++''''+##++''''''''''''''''''';;;;;;;;;;;;;;;;;';'''''++'++++'''''''''''++++++++++++++++++++++++
+''''''++++++++++++++++''++'##++'''''''''''''''''''';';';;;;';;;;;;;;''''''+++++++++''''''''+++++++++++++++++++++++++
+''''++++++++++++++++++'+++'+#++'''''''++++++++++++';';;;;;;;;;;;;;;;;;'+'+++++++++++++++++++++++++++++++++++++++++++
#''+++++++++++++++++++'+++''++++'''''++++########+++;';;;;;;:;;;:::';:'+''+++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++'''+'''+++'''''''+'++######+++''';;'''';:::::;'';+++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++'''''''+++'''''''''''''''++++'''''''+#+';:::,+';;+++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++'';'+'++''''''''++++###@#+#+++'''''+##+';;:,+';++++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++'''';''++'''''''';;'''''+##++++';'++++##++';:;;'++++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++++'''''++'''''''';'''''''''+++';;++##+++#++':;;+++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++#''''''#'''''''';;;;'''''''+';;;'+##@@+'''':;'+++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++#+''''''+''''''';;;;;;'';'''';::;++'++##';;:;++++++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++'##'''''''''''''';;;;;;;;''''';:::;'''';;+;:;'++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++##@#+''''''''';;;;;;;;;;''''';::::;''';;:;:;+++++++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++++##@+''''''''';;;;;;;;;'''''';:::;;;;;;;:,,;+++++++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++++###+''''''''';;;;;;;;;'''''';:,::;:::::::,'+++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++###'''''''''';;;;;;'''+'''';:,;;;;::::::,++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++##+''''''''''''''''''+''''';:,;;;;;::::::++++++++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++++++#+''''''''''''''''''''''';;:,;';;;;:::,'++++++++++++++++++++++++++++++++++++++++++++++++++++
#++++++++++++++++++++++++#'''''''''''''''''''''''';;;:,';;;;:::,+++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++'''''''''''''''''''++#+'';;:,'';;;;::,+++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++'''''''''''+''''''''++++'';;;'+';;;;::+++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++''''''''''''''''''''''++'';;;'+';;;;;'+++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++'''''''''';'+''''''''''''';:;'''';;;:++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++''''''''';;''++'''''''''';:;;'''';;':++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++'''''''''';'''+#+++'''';';;:;;''';';+++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++'''''''''''';'''++++++''';;;;;'';;;:+++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++''''''''''''''''''''''''''+';';':;;'+++++++++++++++++++++++#+++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++'''''''''';'''''''';;;;;''''''';:;;++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++++++++++++''''+''''''''''''''';;;;::::;;';:;'++++++++++++++++++++++++++++++++++++++++++++++++++++++++
###+++####+++++++++++++++'''''''+'''''''''''''';;;;:::;;;:;;+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
###+#++++++++++++++++++++'''''''''''';'''''''''';;;::::;;:;'+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
###+#+####+++#+++++++++++;''''''''''';';;'';;;;;';;;;;:;;;;++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
###++######+++++++++++++;''''''''''''''''''';;;:,:;;;;:;;;+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
###+++++#+#+++#++++++#++'''''''''''''''''''';;;;;::::;;;;.+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
##+#++#++++++++++++++++:+'''''''''''''''''''';;;;:::;::;,.+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+#+#+#+++#++++++#++++';+'''''''''''++''''''''';';;;::;:,,+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#++++##+##+++++++##+++:;+'''''''''''+'''''''''';';::;;::,,,++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+#++++###++++++++#++;;;''''''''''''''+++'''''''';::;;:::,.++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+##+######+++++#++#+;;;'''''''''''+++'+++++'''''';;;::::,,'+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++++++++++#+++::;;''''''''''''+++++'''++'''';;:::::,,.#++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#++++#++#++#+#+++++;;:;;;+'''''''''''++++++'+'''';::::::,,,`+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#++++++#+++++#++':::;:;;;''''''''''''''++++++'';::::::::,;,`:++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++###+++++:,::::;;;;;;+''''''''''''''''''';::::::::::;.``++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#+++++++#++':,::;::;;;;;;;;'''''''''''''''''';;;::::::::;:.``;+++++++++++++++++++++++++++++++++++++++++++++++++++++++
#++++++#+:,::::;;;:;;;;;;;;+'''''''''''''''';;;;;:::::::+,.````;'++++++++++++++++++++++++++++++++++++++++++++++++++++
###+#++:,,:::::;:::;;;;;;;;;+'''''''''''''''';;:;;:;:::';..```,``:+++++++++++++++++++++++++++++++++++++++++++++++++++
#+#++;,::::::;:;;::;;;;;;;;;+'''''';'''''''';;::;;;::;;+,..```..```:+'+++++++++++++++++++++++++++++++++++++++++++++++
#++;,,:::::::;;;;::;;;;;;;;;;+'''';'''''''';;;::;;;;;;+;...````,,````:+++++++++++++++++++++++++++++++++++++++++++++++
#;,,::::::::;::;;:;;;;;;;;;;;''''''';''';;;;;;:;;;;;;'+,...````,,,````.'+++++++++++++++++++++++++++++++++++++++++++++
;:::::::::::::::;:;:;;;;;;:;;;+''';'''';;;;;;;;;;;;;'+:.....```,,,.```.`.'+++++++++++++++++++++++++++++++++++++++++++
':::::::::::::::;:::;:;:;:::;;'''';;;';';;;;;;:;;;;'+;,....````,,,.```..``:++++++++++++++++++++++++++++++++++++++++++
':,::::::::::::;;:::;;;:::::::;+''';;;';;;;;;;;;;;'+':......```,,,..``.,..``'++++++++++++++++++++++++++++++++++++++++
':::::::::::::::;:::;;:::;:::::;+'';;;;;;;;;;;;;;'++;,.....````,,,,.```,,.` `.''+++++++++++++++++++++++++++++++++++++
':::::::::::::::;::::;:;:::::::;+''';'';;;;;;;;;''+;:.....````.,:,..```.,.```.`,'++++++++++++++++++++++++++++++++++++
':::::::::::::::;:::::::;::::::,,''';;';;';;;;;''+;:,..``````..,:,.`.``.,.`````.`:+++++++++++++++++++++++++++++++++++
':::::::::::::::;:::::;;::;::::::''';;'''';;;;''';;:...``````..,:,.``...,.`````....''++++++++++++++++++++++++++++++++
':::::::::::::::;::::::::::::::++,'';;;;;'';'''';::,...``````...::,.`...,...````...`,''++++++++++++++++++++++++++++++
;:,::,,,,,::,:,::::::::::::::+@@@';::;;;;;;;'''':,,.````` `````.,,.````.````` ``..``` :'+++++++++++++++++++++++++++++
*/

// This directive is meant for keeping the items that we need to
// be marked as sensitive PII, PHI info.
// So, it's kind of redundant to have this directive
// apply an attribute after using an attribute to id it as a directive.
// But we will keep this as the standard way to mark items in case we
// ever need to apply more information to these elements or if a new tool
// requires certain ways to mark items, we can retrofit with this funnel.
angular.module('BillPay').directive('sensitive', function(){
    return {
        restrict : 'A',
        scope: false,
        link: function(scope, element){
            element.attr('data-sensitive', 'true');
        }
    };
});