'use strict';

// our app module with dependencies for normal development
angular.module('BillPay', ["ui.router","ngResource","ngMessages","ngSanitize","ngAnimate","pascalprecht.translate","ngResize","720kb.datepicker","smoothScroll","ng.shims.placeholder","newrelic-timing","vcRecaptcha","Templates-Phase1","Templates-Phase2"]
    )
    .constant('_', window._)
    .constant('moment', window.moment)
    .value('Compass', window.Compass || {
        capture: {
            timing: angular.noop,
            event: angular.noop,
            misc: angular.noop,
            metricValue: angular.noop,
            success: angular.noop,
            failure: angular.noop,
            DOMEvent: angular.noop,
            JSError: angular.noop
        }
    })
