angular.module('BillPay').directive('securecodeInput', function($timeout){

    var _MAX_FORMATTED_LENGTH = 11,
        _getFormattedString = function(inputStr){
            var noFormatting = inputStr.split('-').join(''),
                i = 0,
                curChar = '',
                output = '';

            while(i < noFormatting.length){

                curChar = noFormatting[i];

                if(/[A-Za-z0-9]/.test(curChar)){
                    output += curChar.toUpperCase();
                }

                i++;

                if(output.length === 3 || output.length === 7){
                    output += '-';
                }
            }

            return output.slice(0, _MAX_FORMATTED_LENGTH);
        };

    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ngModel){

            var runValidators = function(value){
                    ngModel.$setValidity('allNumericSCode', !/[0-9]{3}-[0-9]{3}-/.test(value));
                },
                validKey = function(keyCode){
                    return /[a-zA-Z0-9-]/.test(String.fromCharCode(keyCode));
                };


            // don't allow more than the formatted length
            el.attr('maxlength', _MAX_FORMATTED_LENGTH);

            el.on('paste', function(){
                $timeout(function(){
                    el.val(_getFormattedString(el.val()));
                }, 50);
            });

            el.on('keyup', function(event){

                var key = event.which || event.keyCode || event.charCode,
                    val = el.val() || '';

                // key sent when browser is processing the event
                // we will pull the character out and get the code directly
                if(key === 229 && val.length > 0 ){
                    key = val.charCodeAt(val.length - 1);
                }


                runValidators(val);

                // if we are deleting, we shouldn't worry about anything
                if(!validKey(key)){
                    return;
                }

                el.val(_getFormattedString(el.val()));

            });

            ngModel.$parsers.push(function(viewValue){
                return _getFormattedString(viewValue);
            });
        }
    };
});