angular.module('BillPay')
    .provider('AppPaths', function () {
        var _appPath = 'templates/',
            _corePath = 'templates/',

            basePaths = {
                app: _appPath,
                core: _corePath + 'core/',
                payment: _appPath + 'payment/',
                default: _appPath + 'default/',
                receipt: _appPath + 'receipt/',
                link: _appPath + 'link/'
            },

            paths = {
                app: {
                    root: basePaths.app + 'app.html'
                },
                core: {
                    navigation: basePaths.core + 'navigation.tpl.html',
                    footer: basePaths.core + 'footer.tpl.html',
                    error: basePaths.core + 'error.tpl.html'
                },
                default: {
                    root: basePaths.default + 'default.landing.tpl.html',
                    feedback: basePaths.default + 'default.feedback-modal.tpl.html'
                },
                payment: {
                    root: basePaths.payment + 'payment.tpl.html',
                    amount: basePaths.payment + 'payment.amount.tpl.html',
                    date: basePaths.payment + 'payment.date.tpl.html',
                    method: basePaths.payment + 'payment.method.tpl.html',
                    address: basePaths.payment + 'payment.address.tpl.html',
                    contact: basePaths.payment + 'payment.contact.tpl.html',
                    confirm: basePaths.payment + 'payment.confirm.tpl.html'
                },
                receipt: {
                    root: basePaths.receipt + 'receipt.tpl.html',
                    view: basePaths.receipt + 'receipt.content.tpl.html'
                },
                link: {
                    root: basePaths.link + 'link.tpl.html',
                    init: basePaths.link + 'link.init.tpl.html'
                }
            };

        return {
            get: function () {
                return paths;
            },
            $get: function () {
                return paths;
            }
        };

    });
