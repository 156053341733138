angular.module('BillPay').directive('validPassword', function(_){
    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ngModel){

            ngModel.$validators.minCharactersRule = function(modelValue) {
                return _.trim(modelValue).length >= 8;
            };

            ngModel.$validators.upperCaseRule = function(modelValue) {
                return /[A-Z]+/.test(_.trim(modelValue));
            };

            ngModel.$validators.digitRule = function(modelValue) {
                return /\d+/.test(_.trim(modelValue));
            };
        }
    };
});