angular.module('BillPay')
    .config(function ($stateProvider, $urlRouterProvider, AppStatesProvider, AppPathsProvider) {

        var states = AppStatesProvider.get(),
            paths = AppPathsProvider.get();

        $urlRouterProvider.rule(function ($injector, $location) {
            var path = $location.url();
            if (path[path.length - 1] === '/' || !!~path.indexOf('/?')) {
                return;
            }
            if (~path.indexOf('?')) {
                return path.replace('?', '/?');
            }
            return path + '/';
        });

        $stateProvider
            .state(states.core.root, {
                abstract: true,
                url: '/',
                templateUrl: paths.app.root
            })
            .state(states.core.error, {
                params: {
                    'status' : 404,
                    'statusText': 'Not Found',
                    'errorCode': 'NOT_FOUND',
                    'additionalData': null
                },
                templateUrl: paths.core.error,
                controller: 'ErrorCtrl'
            });

        $urlRouterProvider.otherwise(function($injector, $location){
            var state = $injector.get('$state');
            state.go('app.error');
            return $location.path();
        });
    })


    // Mobile Setup
    .run(function() {
        if(window.FastClick){
            window.FastClick.attach(document.body);
        }
    })

    .run(['$log', 'Config', function enhanceAngularLog ($log, Config) {
        $log.enabledContexts = [];

        $log.getInstance = function(context, forceLog) {
            if (forceLog) {
                $log.enabledContexts[context] = true;
            } else if (Config.disableLogging) {
                $log.enabledContexts[context] = false;
            }

            return {
                log: enhanceLogging($log.log, context),
                info: enhanceLogging($log.info, context),
                warn: enhanceLogging($log.warn, context),
                debug: enhanceLogging($log.debug, context),
                error: enhanceLogging($log.error, context),
                enableLogging: function(enable) {
                    $log.enabledContexts[context] = enable;
                }
            };
        };

        function enhanceLogging(loggingFunc, context) {
            return function() {
                var contextEnabled = $log.enabledContexts[context], modifiedArguments = [];
                if ($log.enabledContexts[context] == null || contextEnabled) {
                    modifiedArguments = [].slice.call(arguments);
                    /*eslint no-undef: 0*/
                    modifiedArguments[0] = moment().format('dddd h:mm:ss a') + ' [' + context + '] >> ' + modifiedArguments[0];
                    loggingFunc.apply(null, modifiedArguments);
                }
            };
        }
    }])

    // Locale Setup
    .config(['$provide', function($provide) {
        $provide.decorator('$locale', ['$delegate', function($delegate) {
            if($delegate.id === 'en-us') {
                $delegate.NUMBER_FORMATS.PATTERNS[1].negPre = '-\u00A4';
                $delegate.NUMBER_FORMATS.PATTERNS[1].negSuf = '';
            }
            return $delegate;
        }]);
    }])

    // Tranlation Config
    .config(['$translateProvider', function($translateProvider) {
        $translateProvider.preferredLanguage('en');
        $translateProvider.useMissingTranslationHandlerLog();
    }])

    // Ajax Configurations

    .config(function ($httpProvider) {

        $httpProvider.defaults.transformResponse.push(function (value) {

            if(angular.isObject(value) && 'data' in value){
                value.hasData = function(){
                    return (angular.isArray(this.data) && this.data.length > 0) || !!value.data;
                };
                value.getData = function(){
                    return this.data;
                };
            }
            return value;
        });



        $httpProvider.interceptors.push(function ($rootScope, $log, $q, ErrorsService, ServerStatusService) {
            var _processResponse = function(response) {
                //400s should still be processed as if it were a 200.  404s and 500s should be rejected.
                if (response.status > 400) {
                    return $q.reject(response);
                }

                var serverReponse = angular.isDefined(response.data) && angular.isDefined(response.data.data) ? response.data : null;
                if (serverReponse){
                    if (serverReponse.devMessage){
                        $log.info('SERVER MESSAGE:', serverReponse.devMessage);
                    }
                    if (serverReponse.gatewayStatus){
                        ServerStatusService.updatePaymentStatus(serverReponse.gatewayStatus);
                    }
                    if(serverReponse.errorCode){
                        return $q.reject(serverReponse);
                    }
                }
                return response;
            };

            return {
                'request': function(config) {
                    return config;
                },

                'requestError': function(rejection) {
                    return $q.reject(rejection);
                },

                'responseError': function(rejection) {
                    return _processResponse(rejection);
                },

                'response': function(response) {
                    return _processResponse(response);
                }
            };
        });
    });
