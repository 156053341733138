angular.module('BillPay')
    .controller('LandingCtrl', function ($scope, $rootScope, $filter, AppStates,
                                        $translate, ProviderPaymentService, Provider) {

        $scope.Provider = Provider;

        $translate(['meta.title', 'meta.description'], {'providerName': Provider.name}).then(function (translations) {
            $rootScope.metaTitle = translations['meta.title'];
            $rootScope.metaDescription = translations['meta.description'];
        });

        $scope.marketingBlurb = $filter('translate')('landing.marketingBlurb', {
            providerName: Provider.name
        });

        $scope.accountInvalidMessage = Provider.accountNumberErrorMessage ? Provider.accountNumberErrorMessage : $filter('translate')('errors.accountInvalid');

        $scope.belowMinError = $filter('translate')('errors.belowMinAmount', {
            minAmount: $filter('currency')(Provider.limits.minimumPaymentAmount)
        });

        var submitting = false;

        $scope.startPayment = function () {
            if (submitting || $scope.accountAmountForm.$invalid) {
                return;
            }
            submitting = true;
            ProviderPaymentService.createProviderPaymentFromBill($scope.accountNumber, $scope.billAmount)
                .then(function () {
                    $scope.goToState(AppStates.payment.amount);
                });
        };

        $scope.learnMore = function() {
            $scope.openTab('https://www.patientco.com/happy-patients/');
        };
    });
