angular.module('BillPay').factory('Url2ObjService', function(_){
    var _isNum = function(n){
            // check for if we can parse to an int, don't do this for leading
            // zero values
            return !isNaN(parseFloat(n)) && isFinite(n) && n[0] !== '0';
        },

        // query is the query string excluding the ?
        //  example: var1=1&something=this
        _parseQueryString = function(hydrateObj, query){
            var assignments = query.split('&'),
                temp, prop, val;

            _.forEach(assignments, function(part){
                temp = part.split('=');
                prop = temp[0];
                val = temp.length > 1 ? decodeURIComponent(temp[1].replace(/\+/g)) : '';

                hydrateObj[prop] = _isNum(val) ? parseFloat(val) : val;
            });
        },

        // url is the url we need to extract the vals from
        //      example: /something/23/find/AAA-BBB-CCC
        //
        // interpUrl is the url with the interpolation variables
        //      we need this to extract the variable names for the assignment
        //      based on the vals from the url
        //      example for above url: /something/:id/find/:sCode
        _parseInterpolatedUrl = function(hydrateObj, url, interpUrl){

            var interpParts = interpUrl.split('/'),
                urlParts = url.split('/'),
                prop, val;

            _.forEach(interpParts, function(part, index){
                if(part[0] === ':'){
                    prop = part.substr(1);
                    val = urlParts[index];

                    if(val !== undefined){
                        hydrateObj[prop] = _isNum(val) ? parseFloat(val) : val;
                    }
                }
            });
        };

    return {

        /**
            Function to take a url and pull out the
                variables. If the url used interpolation
                to populate different route parameters,
                passing that prepopulated interpolationUrl
                will allow us to also extract those variables
        */
        parse: function(url, interpolationUrl){

            var obj = {},

                // get query string parameters
                query = url.split('?');

            if(query.length > 1){
                _parseQueryString(obj, query.pop());
            }

            if(interpolationUrl){
                _parseInterpolatedUrl(obj, query.shift(), interpolationUrl);
            }

            return obj;
        }
    };
});
