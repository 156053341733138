angular.module('BillPay').factory('Base64Service', function(){
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

    function InvalidCharacterError(message) {
        this.message = message;
    }

    InvalidCharacterError.prototype = new Error;
    InvalidCharacterError.prototype.name = 'InvalidCharacterError';

    return {
        decode: function(input) {
            var str = String(input).replace(/=+$/, ''),
                output = '',
                bc = 0,
                bs,
                buffer,
                idx = 0;

            if (str.length % 4 == 1) {
                throw new InvalidCharacterError('atob failed: The string to be decoded is not correctly encoded.');
            }

            for ( ; ;
                    // character found in table? initialize bit storage and add its ascii value;
                    ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
                    // and if not first of each 4 characters,
                    // convert the first 8 bits to one ascii character
                    bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
            ) {
                buffer = str.charAt(idx++);
                if (!buffer) {
                    break;
                }

                // try to find character in table (0-63, not found => -1)
                buffer = chars.indexOf(buffer);
            }

            return output;
        }
    };
});