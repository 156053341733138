angular.module('BillPay')
    .directive('bpAlert', function($compile, _){
        return {
            restrict : 'E',
            scope: {
                toggle: '=',
                text: '='
            },
            replace: true,
            template: '<div class="bp-alert" ng-show="toggle"></div>',
            link: function($scope, element, attrs) {
                var alerts = [
                    { type:'success', class: 'bp-alert-success', icon: 'circle-check' },
                    { type:'info', class: 'bp-alert-info', icon: 'info' },
                    { type:'warning', class: 'bp-alert-warning', icon: 'warning' },
                    { type: 'error', class: 'bp-alert-error', icon: 'circle-x'}
                ];

                $scope.alert = _.find(alerts, { 'type': attrs.type }) || alerts[0];
                element.addClass($scope.alert.class);
                element.append('<icon name="' + $scope.alert.icon + '"></icon>');
                element.append('<span ng-bind=":: text"></span>');

                $compile(element.contents())($scope);
            }
        };
    });
