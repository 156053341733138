angular.module('BillPay').directive('triggerModal', function($rootScope, $filter) {

    var triggerModal = function(type) {
        if (type === 'terms') {
            $rootScope.$emit('simpleModal:showPrompt', {
                header: $filter('translate')('dialogs.termsTitle'),
                subcontent: '<iframe src="terms.html" seamless="true"></iframe>',
                actions: [{
                    label: $filter('translate')('actions.close'),
                    includeClasses: 'button-primary'
                }]
            });
            $rootScope.$apply();
        } else {
            return;
        }
    };

    return {
        restrict: 'A',
        compile: function(element, attrs) {
            var type = attrs.triggerModal;
            return function($scope, element) {
                element.bind('click', function() {
                    triggerModal(type);
                });
            };
        }
    };

});
