angular.module('BillPay').directive('match', function(_) {

    // Simple directive to validate if an input value
    // explicitely matches another

    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            match: '='
        },

        link: function($scope, elem, attrs, ngModel) {

            $scope.$watch(function() {
                return (ngModel.$pristine && angular.isUndefined(ngModel.$modelValue)) || _.trim($scope.match) === _.trim(ngModel.$modelValue);
            }, function(currentValue) {
                ngModel.$setValidity('mismatch', currentValue);
            });
        }
    };

});