angular.module('BillPay').filter('methodDesc', function($log, _, CardService){

    var _card = function(m, s, separator){

            var output = [];

            switch (s) {
                case 'short':
                    output = [CardService.getIssuerFromCardType(m.cardType), '****' + m.last4Digits];
                    break;
                default:
                    output = [CardService.getIssuerFromCardType(m.cardType), '****' + m.last4Digits];
                    break;
            }

            return output.join( separator === undefined ? ' ' : separator);
        },

        _eCheck = function(m, s, separator){

            var output = [];

            // we aren't always consistent with
            // how we display these values, so we will check
            // for the routing if we don't see a 4 digits
            if(!m.last4Digits && m.accountNumber){
                m.last4Digits = m.accountNumber.slice(-4);
            }

            switch (s) {
                case 'short':
                    output = ['eCheck', '**' + m.last4Digits];
                    break;
                default:
                    output = ['eCheck', m.bankName, '**' + m.last4Digits];
                    break;
            }

            return output.join( separator === undefined ? ' ' : separator);
        };

    return function(method, style, separator){

        var acceptedStyles = [ 'short' ],
            officialStyle;

        if(!method){
            return '';
        }

        if(style && acceptedStyles.indexOf(style) > -1){
            officialStyle = style.toLowerCase();
        }

        return method.formType.toLowerCase() === 'card' ? _card(method, officialStyle, separator) : _eCheck(method, officialStyle, separator);
    };

});
