angular.module('BillPay')
    .controller('LinkTokenCtrl', function ($scope, $rootScope, $filter, $stateParams, $timeout, AppStates, ErrorsService, TokenService, ProviderPaymentService) {

        $scope.token = $stateParams.token;

        /*****
         * Show the loading indicator while we call the token decryption service
         *****/
        // Hide the container to keep the page from flashing
        document.querySelector('.view-layout-transition').style.display = 'none';
        $timeout(function(){
            $rootScope.$broadcast('showLoadingIndicator');
            // Then, reshow the page once we've reactivated the loading indicator
            $timeout(function(){
                document.querySelector('.view-layout-transition').style.display = 'block';
            }, 0);
        }, 0);

        /*****
         * Call the token decryption service
         *****/
        TokenService.getPayload($scope.token).then(function(payload){
            $scope.payload = payload;

            // Validate providerSlug
            if($rootScope.providerSlug === $scope.payload.providerSlug
                && angular.isNumber($scope.payload.amount)
                && $scope.payload.amount > 0
                && angular.isString($scope.payload.accountNumber)
                && $scope.payload.accountNumber.length > 0
            ) {
                ProviderPaymentService.createProviderPaymentFromBill($scope.payload.accountNumber, $scope.payload.amount)
                    .then(function () {
                        $rootScope.isGuestPay = true;
                        $scope.goToState(AppStates.payment.amount);
                    }, function(err){
                        $scope.goToState(AppStates.core.error, {
                            'status': 404,
                            'statusText': 'Not Found',
                            'errorCode': ErrorsService.resolve({'errorCode': 'NOT_FOUND'})
                        });
                    });
            }else{
                $scope.goToState(AppStates.core.error, {
                    'status': 404,
                    'statusText': 'Not Found',
                    'errorCode': ErrorsService.resolve({'errorCode': 'NOT_FOUND'})
                });
            }
        }, function(err){
            $scope.payload = false;            
            $scope.goToState(AppStates.core.error, {
                'status': 404,
                'statusText': 'Not Found',
                'errorCode': ErrorsService.resolve({'errorCode': 'NOT_FOUND'})
            });
        }).finally(function(){
            // Last, hide the loading indicator
            $timeout(function(){
                $rootScope.$broadcast('hideLoadingIndicator');
            }, 0);
        });
    });
