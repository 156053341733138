angular.module('BillPay')
    .factory('CoreModel', function (Cache, $log, _) {

        return function (name, proto) {

            var ModelLogger = $log.getInstance(name);

            function Model (data) {
                data = data || {};

                if (angular.isFunction(this.deserialize)) {
                    this.deserialize(data);
                } else {
                    angular.extend(this, {}, data);
                }

                if(angular.isFunction(this.init)) {
                    this.init();
                }
            }

            function set (fieldName, value) {
                if (angular.isUndefined(fieldName) || angular.isUndefined(value)) {
                    ModelLogger.error('Invalid set parameters', fieldName, value);
                    return false;
                }

                if (!this.validate(fieldName, value)) {
                    return false;
                }

                this[fieldName] = value;
                ModelLogger.info('Set ' + name + ' ' + fieldName + ':', value);
                this.isDirty(true);
                return true;
            }

            function validate (fieldName, value) {
                var validateFunc = this['validate' + _.upperFirst(fieldName)];
                if (angular.isFunction(validateFunc)) {
                    value = value || this[fieldName];
                    return validateFunc(value, this);
                }

                ModelLogger.warn('No validation found for ' + fieldName);
                return true;
            }

            function isSet (fieldName) {
                return angular.isDefined(this[fieldName]);
            }

            function isValid (fieldNames) {
                fieldNames = fieldNames || (this.getValidatedFields ? this.getValidatedFields() : []);
                var model = this,
                    failedValidations = _.filter(fieldNames, function (field) {
                        return !model.validate(field);
                    });
                return failedValidations.length === 0;
            }

            function isDirty (bool) {
                if (angular.isUndefined(bool)) {
                    return !!this.$$dirty;
                }
                return this.$$dirty = bool;
            }

            Model.prototype = angular.extend({}, {
                logger: ModelLogger,
                set: set,
                validate: validate,
                isSet: isSet,
                isValid: isValid,
                isDirty: isDirty
            }, proto);

            return Model;

        };

    });
