angular.module('BillPay').service('TokenService', function($rootScope, $resource, $q, _, endpoints, Cache, $log){

    var _requestCache = new Cache('token-request'),
        Token = $resource(endpoints.token.contents.url, { token: '@token' });

    return {
        getPayload : function(token) {
            var defer = $q.defer(), existingPromise = {};

            if(angular.isString(token) && token !== '') {

                existingPromise = _requestCache.get(token);
                if(angular.isDefined(existingPromise)) {
                    return existingPromise.promise;
                }
                
                _requestCache.add(defer, token);

                Token.get({token: token}).$promise.then(function(resp) {
                    if (resp && resp.hasData()) {
                        return resp.getData();
                    }
                    _requestCache.delete(token);
                    defer.reject();
                }).then(defer.resolve, defer.reject);

            }else{
                defer.reject({'status': 404});
            }            

            return defer.promise;
        }
    };
});
