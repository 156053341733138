/*
    a standard way of combining a list of words into human-format
*/

angular.module('BillPay').filter('listCombine', function( _, $filter ){
    return function (wordList) {
        if (wordList.length === 0) {
            return '';
        } else if (wordList.length === 1) {
            return wordList[0];
        }

        return _.initial(wordList).join(', ') + ' ' + $filter('translate')('misc.conjunction') + ' ' + _.last(wordList);
    };
});