angular.module('BillPay').factory('OverlayService', function(_){

    var stack = [];

    return {

        // pass the click callback so that when
        // we get a overlay click we can perform
        // whichever mutation we need for that piece
        // of ui - e.g. modal is different than nav cb
        show : function( key, clickCallback ){

            if(_.isFunction(key)){
                clickCallback = key;
                key = _.uniqueId('overlay_');
            }

            if(!key){
                key = _.uniqueId('overlay_');
            }

            this.isVisible = true;

            var keyIndex = _.findLastIndex(stack, function(ov){
                return ov.key === key;
            });

            if(keyIndex === -1){
                stack.push({ key: key, cb: clickCallback || null });
            }

            return key;
        },

        // we require a key because we don't want a
        // closer to inadvertently hide the overlay for another section
        hide: function(key){

            var keyIndex = _.findLastIndex(stack, function(ov){
                return ov.key === key;
            });

            if(keyIndex === -1){
                return;
            }

            stack.splice(keyIndex, 1);

            if(stack.length === 0){
                this.isVisible = false;
            }
        },

        clicked: function(){
            var item = stack[stack.length - 1];
            if(item && item.cb){
                item.cb();
            }
        },

        isVisible: false
    };
});
