angular.module('BillPay')
    .controller('PaymentContactCtrl', function ($scope, Payment) {

        $scope.emailAddress = Payment.emailAddress;

        $scope.setContactInfo = function (emailAddress) {
            if ($scope.forms.contactForm.$invalid) return;

            if (Payment.set('emailAddress', emailAddress)) {
                $scope.continue();
            }
        };
    });
