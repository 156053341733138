angular.module('BillPay').directive('notification', function(){
    return {
        restrict : 'E',
        scope: {
            // we know that the user wants a close toggle
            // if they subscribe to the close event
            whenClose: '&',
            iconName: '@'
        },
        transclude: true,
        replace: true,
        link: function($scope, el, attrs){
            $scope.closable = !!attrs.whenClose;
            $scope.hasIcon = !!attrs.iconName;
            $scope.iconName = attrs.iconName;

            $scope.closeNotification = function(){
                if(angular.isFunction($scope.whenClose)){
                    $scope.whenClose();
                }
            };
        },
        template: '<div class="notification" ng-class="{closable: closable, \'has-icon\': hasIcon}"><div class="primary-icon-container u-center-vertical"><icon name="{{iconName}}"></icon></div><div class="notification-content-container u-center-vertical"><div class="notification-content" ng-transclude></div></div><div class="notification-close u-center-vertical" ng-click="closeNotification()"><icon name="x" size="sm"></icon></div></div>'
    };
});