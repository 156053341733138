angular.module('BillPay')
    .controller('PaymentCtrl', function ($scope, $rootScope, $filter, $log, $timeout,
                                         Provider, Payment, ProviderPaymentService, AppStates, _) {

        $scope.Provider = Provider;
        $scope.Payment = Payment;
        $scope.forms = {};

        var logger = $log.getInstance('payment-ctrl'),
            visitedStates = [],

            _isCompletedState = function (state) {
                switch (state) {
                    case AppStates.payment.amount:
                        return Payment.validate('amount');
                    case AppStates.payment.date:
                        return Payment.validate('paymentDate');
                    case AppStates.payment.method:
                        return Payment.validate('method') && Payment.validate('paymentForm');
                    case AppStates.payment.address:
                        return Payment.validate('streetAddress') && Payment.validate('zip');
                    case AppStates.payment.contact:
                        return Payment.validate('emailAddress');
                    case AppStates.payment.confirm:
                        return Payment.isValid() && Payment.isCompleted();
                }
                return false;
            };

        logger.info('Initializing payment flow with', Payment);

        $scope.translationData = {
            todayDate: $filter('dateFormat')(new Date(), 'LL')
        };

        $scope.isActiveState = function (state) {
            return !!~visitedStates.indexOf(state) || _isCompletedState(state);
        };

        $scope.goToNextIncompleteState = function () {
            for (var state in AppStates.payment) {
                state = AppStates.payment[state];
                if (state !== AppStates.payment.root && !_isCompletedState(state)) {
                    if (!~visitedStates.indexOf(state)) visitedStates.push(state);
                    $scope.progress = (visitedStates.length / (_.keys(AppStates.payment).length-1)) * 100;
                    return !$scope.isState(state) && $scope.goToState(state);
                }
            }
        };

        $scope.continue = function (callback) {
            ProviderPaymentService.updatePayment(Payment);
            $scope.goToNextIncompleteState();
            if (angular.isFunction(callback)) {
                $timeout(callback, 10);
            }
        };

        $scope.$on('$stateChangeStart', function (e, toState, toStateParams, fromState, fromStateParams) {
            if (angular.isDefined(toState) && angular.isDefined(fromState)) {
                var paymentStates = _.values(AppStates.payment);
                $scope.reverseTransition = paymentStates.indexOf(toState.name) < paymentStates.indexOf(fromState.name);
            }
        });

    });
