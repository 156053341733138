angular.module('BillPay')
    .factory('PaymentModel', function (CoreModel, PaymentFormModel,
                                    PAYMENT_STATE, PAYMENT_METHOD, ServerStatusService, moment, _) {

        return CoreModel('payment', {

            deserialize: function (data) {
                this.transactionId = data.transactionId;
                this.status = data.status;

                this.accountNumber = data.accountNumber;
                this.amountDue = data.amountDue;
                this.providerSlug = data.provider ? data.provider.urlSlug : data.providerSlug;

                this.amount = data.amount;

                var paymentDate = (data.dateScheduled || data.dateProcessed) || data.paymentDate;
                if (angular.isDefined(paymentDate)) {
                    this.paymentDate = moment(paymentDate, 'YYYY-MM-DD').isValid() ?
                                       moment(paymentDate, 'YYYY-MM-DD') : moment(paymentDate);
                }

                this.method = data.method;
                this.paymentForm = data.paymentForm instanceof PaymentFormModel
                                    ? data.paymentForm : new PaymentFormModel(data.paymentForm);
                this.streetAddress = data.streetAddress;
                this.zip = data.zip;
                this.emailAddress = data.emailAddress;
                this.completed = data.completed;
            },

            serialize: function () {
                return {
                    accountNumber: this.accountNumber,
                    amountDue: this.amountDue,
                    providerSlug: this.providerSlug,
                    amount: this.amount,
                    paymentDate: this.paymentDate ? this.paymentDate.format('YYYY-MM-DD') : undefined,
                    method: this.method,
                    paymentForm: this.paymentForm.serialize(),
                    streetAddress: this.streetAddress,
                    zip: this.zip,
                    emailAddress: this.emailAddress,
                    completed: this.completed
                };
            },

            getValidatedFields: function () {
                return ['providerSlug', 'accountNumber', 'amountDue', 'amount',
                'paymentDate', 'method', 'paymentForm', 'streetAddress', 'zip',
                'emailAddress'];
            },

            // Validations

            validateAccountNumber: function (val, payment) {
                return angular.isString(val) && val.length > 0;
            },

            validateAmountDue: function (val, payment) {
                return angular.isNumber(val) && val > 0;
            },

            validateProviderSlug: function (val, payment) {
                return angular.isString(val) && val !== '';
            },

            validateAmount: function (val, payment) {
                return angular.isNumber(val) && val > 0;
            },

            validatePaymentDate: function (val, payment) {
                return moment.isMoment(val) && val.isValid() && val.diff(moment(), 'days') >= 0;
            },

            validateMethod: function (val, payment) {
                return angular.isString(val) && !!~_.values(PAYMENT_METHOD).indexOf(val)
                        && ServerStatusService.getPaymentStatus(val);
            },

            validatePaymentForm: function (val, payment) {
                return angular.isObject(val) && val instanceof PaymentFormModel
                    && (!payment || payment.method === val.formType)
                    && (!val.formType || ServerStatusService.getPaymentStatus(val.formType))
                    && val.isValid(['firstName', 'lastName'])
                    && ((val.isCredit() && val.isValid(['cardType', 'cardNumber', 'expDate'])
                        && (!payment || !payment.expiringBeforePaymentDate(val.expDate)))
                    || (val.isECheck() && val.isValid(['routingNumber', 'accountNumber'])));
            },

            validateStreetAddress: function (val, payment) {
                return angular.isString(val) && val !== '';
            },

            validateZip: function (val, payment) {
                return angular.isString(val) && val.length === 5;
            },

            validateEmailAddress: function (val, payment) {
                return angular.isString(val) && _.includes(val, '@');
            },

            // Util

            payingAmountDue: function () {
                return angular.isDefined(this.amount) && this.amount === this.amountDue;
            },

            payingToday: function () {
                if (angular.isUndefined(this.paymentDate)) {
                    return false;
                }
                return moment().diff(this.paymentDate, 'days') === 0;
            },

            expiringBeforePaymentDate: function (expDate) {
                return angular.isString(expDate) && !this.payingToday() && this.validate('paymentDate')
                        && moment(expDate, 'MMYY').add(1, 'month').isBefore(this.paymentDate);
            },

            clearPaymentForm: function () {
                delete this.method;
                this.paymentForm = new PaymentFormModel();
            },

            isCompleted: function () {
                return !!this.completed;
            }

        });
    });
