
angular.module('BillPay').directive('icon', function($compile) {

    var imgTpl = '<img data-src="" name="{{imgName}}" class="iconic" iconic ng-class="{ \'icon-active\': active}" />';

    return {
        restrict: 'E',
        replace: false,
        scope: {
            active: '=',
            imgName: '@name'
        },
        template: imgTpl,
        link: function(scope, el, attrs){
            // attrs.for === an input id -- this matches the label#for spec
            // we should add a class to this element that signifies we need to
            // position it next to an input, and ad a class to the input with that
            // "for" id that signifies it has an icon next to it

            if(attrs.for){


                el.children('img').addClass('icon-for-input');

                scope.$watch('active', function(newValue){
                    if(newValue){
                        el.addClass('icon-active');
                    }else {
                        el.removeClass('icon-active');
                    }
                });

                if(attrs.position === 'right'){
                    el.children('img').addClass('icon-for-input-pull-right');
                    el.next('#' + attrs.for).addClass('input-with-right-icon');
                }else{
                    // mark the next sibling input with the icon status
                    el.next('#' + attrs.for).addClass('input-with-icon');
                }
            }



            // allow for dynamic icons
            scope.$watch('imgName', function(newVal, oldVal){
                if(newVal !== oldVal){

                    el.html(imgTpl);

                    if(el.attr('for')){
                        el.children('img').addClass('icon-for-input');

                        if(el.attr('position') === 'right'){
                            el.children('img').addClass('icon-for-input-pull-right');
                        }
                    }

                    $compile(el.contents())(scope);
                }
            });
        }
    };
});


// Watch for iconic icons and run the iconic injector
angular.module('BillPay').directive('iconic', function($window, $log, _) {

    var iconicJS,
        injection =  function(newLength) {

            // TODO: size is not being respected

            // we async load the iconic script so we need to
            // make sure it's loaded. Note, we don't have to invoke
            // the injector once it is loaded because it does that automatically
            if(!iconicJS && !$window.IconicJS){
                return;
            }else if(!iconicJS){
                iconicJS = $window.IconicJS();
            }

            if( newLength > 0 ) {
                iconicJS.inject('img.iconic[data-src^="assets"]');
            }
        };

    return {
        restrict: 'A',
        link: function(scope, element, attrs) {

            if(attrs.name){
                element.attr('data-src', 'assets/icons/' + attrs.name + '-' + (attrs.size || 'md') + '.svg');
            }

            scope.$watch(function() {
                // Watch for the number of iconic DOM elements
                return _.map($window.document.querySelectorAll('img.iconic[data-src^="assets"]'), function(el){
                    return !!el.parentNode;
                }).length;
            }, injection);
        }
    };
});
