angular.module('BillPay').directive('paymentFlowLink', function (AppStates) {
    return {
        restrict : 'E',
        scope: {
            state: '@',
            text: '@'
        },
        replace: true,
        link: function($scope, element, attrs) {

            var state = AppStates.payment[$scope.state];

            $scope.$on('$stateChangeSuccess', function () {
                element.attr('disabled', !$scope.$parent.isActiveState(state));
                element.toggleClass('current', $scope.$parent.isState(state));
            });

            element.bind('click', function () {
                if ($scope.$parent.isActiveState(state)) {
                    $scope.$parent.goToState(state, {}, false);
                }
            });
        },
        template: '<a class="flow-link {{state}}" ng-bind=":: \'{{text}}\' | translate"></a>'
    };
});
