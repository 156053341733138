angular.module('BillPay')
    .controller('PaymentConfirmCtrl', ['$scope', '$filter', 'ProviderPaymentService', 'ErrorsService', 'AppStates', 'Config', 'vcRecaptchaService', function ($scope, $filter, ProviderPaymentService, ErrorsService, AppStates, Config, recaptcha) {

        var submitting = false;

        $scope.gatewayRetryRequested = false;
        $scope.acceptedTerms = false;
        $scope.confirmDialog = $filter('translate')('actions.processPayment');
        $scope.processingNotifications = {};

        $scope.widgetId = null;
        $scope.recaptchaResponse = null;
        $scope.payment = null;
        $scope.recaptchaKey = Config.recaptchaKey;

        $scope.setWidgetId = function (widgetId) {
            $scope.widgetId = widgetId;
        };

        $scope.setRecaptchaResponse = function (response) {
            $scope.recaptchaResponse = response;
            $scope.makePayment($scope.payment);
        };

        $scope.recaptchaExpiration = function() {
            recaptcha.reload($scope.widgetId);
        };

        $scope.submitForm = function (payment) {
            $scope.payment = payment;
            recaptcha.execute($scope.widgetId);
        };

        $scope.makePayment = function (payment) {
            if (!$scope.acceptedTerms)  {
                $scope.processingNotifications.tosRequired = true;
                $scope.recaptchaExpiration();
                return;
            }

            $scope.processingNotifications.tosRequired = false;

            if (!submitting && payment.isValid()) {

                $scope.processed = submitting = true;

                $scope.confirmDialog = $filter('translate')(payment.payingToday()
                    ? 'labels.submittingPayment' : 'labels.submittingPaymentFuture');

                ProviderPaymentService.processProviderPayment(payment, $scope.recaptchaResponse, $scope.gatewayRetryRequested).then(
                    function (payment) {
                        $scope.goToState(AppStates.receipt.view, { payment: payment });
                    },
                    function (error) {
                        $scope.gatewayRetryRequested = submitting = false;
                        $scope.confirmDialog = error === ErrorsService.errors.GATEWAY_RETRY_REQUESTED ?
                            $filter('translate')('actions.retryProcessPayment') :
                            $filter('translate')('actions.processPayment');

                        switch (error) {
                            case ErrorsService.errors.GATEWAY_RETRY_REQUESTED:
                                $scope.gatewayRetryRequested = true;
                                $scope.processingNotifications.retryProcess = true;
                                break;
                            case ErrorsService.errors.METHOD_UNAVAILABLE:
                                $scope.processingNotifications[payment.method + 'Unavailable'] = true;
                                payment.deletePaymentForm();
                                break;
                            case ErrorsService.errors.PAYMENT_DECLINED:
                                $scope.processingNotifications.declinedCard = true;
                                break;
                            case ErrorsService.errors.PREMATURE_EXPIRATION:
                                $scope.processingNotifications.prematureExpiration = true;
                                break;
                            case ErrorsService.errors.PAYMENT_INVALID_CARD_NUMBER:
                            case ErrorsService.errors.PAYMENT_CARD_EXPIRED:
                                $scope.processingNotifications.invalidCardInformation = true;
                                break;
                            case ErrorsService.errors.PAYMENT_DUPLICATE:
                                $scope.processingNotifications.duplicatePayment = true;
                                break;
                            default:
                                $scope.processingNotifications.processingError = true;
                                break;
                        }
                    }
                );
            } else if (!payment.isValid()) {
                $scope.goToNextIncompleteState();
            }
        };
    }]);
