angular.module('BillPay').service('ContextHelperService', function($rootScope){
    /*
        This is a highlevel service that reflects it's state on the ui.
        The AppRootCtrl binds this to its scope and it's prototypically
        available to child scopes and thus the ui
    */

    $rootScope.$on('$stateChangeSuccess', function(){
        if(_this.breadCrumbVisible){
            _destroy();
        }
    });

    var _currentBreadCrumb,
        _this = this,
        _destroy = function(){
            _this.breadCrumbVisible = false;
            _currentBreadCrumb = null;
        };

    this.showBreadCrumbs = function(specs){
        _currentBreadCrumb = specs;
        this.breadCrumbVisible = true;
        this.breadCrumbLabel = specs.label;
    };

    this.breadCrumbVisible = false;
    this.breadCrumbLabel = '';


    this.breadCrumbClick = function(){
        _currentBreadCrumb.onClick();
        _destroy();
    };

});