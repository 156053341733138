angular.module('BillPay')
    .controller('PaymentDateCtrl', function ($scope, $filter, Payment, Provider,
                                             ProviderPaymentService, moment) {

        $scope.paymentDate = Payment.paymentDate ? Payment.paymentDate.format('MMM D, YYYY') : undefined;

        $scope.displayDate = $filter('translate')('payment.date.todayArg', $scope.translationData);

        $scope.dateLimits = {
            min: moment().toDate().toDateString(),
            max: moment().add(Provider.limits.paymentMaxScheduleDays, 'days').toDate().toDateString()
        };

        $scope.dateErrors = {};

        $scope.showCalendar = function () {
            $scope.toggleCalendar = true;
        };

        $scope.selectedDate = function () {
            if (angular.isDefined($scope.dateRequired)) {
                $scope.dateRequired = false;
            }
        };

        $scope.setPaymentDate = function (date) {
            if (angular.isUndefined(date) && $scope.toggleCalendar) {
                $scope.dateErrors.required = true;
                return;
            }

            date = date ? moment(date, 'MMM D, YYYY') : moment();
            ProviderPaymentService.validateProviderPaymentDate(date).then(
                function (isValid) {
                    if (isValid && Payment.set('paymentDate', date)) {
                        $scope.continue(function () {
                            $scope.toggleCalendar = !Payment.payingToday();
                        });
                    }
                }
            );
        };
    });
