angular.module('BillPay').service('PaymentGatewayService', function( $resource, $q, $log, _, endpoints, GatewayFactoryService, ErrorsService, Compass, PAYMENT_METHOD, Config){

    // Payments resource kept in this service
    // because it is the only service that
    // needs to know them, currently. Also,
    // we would need to do some refactoring
    // if we added them to the PaymentsService
    // because it would introduce a circular
    // dependency problem
    var Payments = $resource(endpoints.payments.prepGateway.url, null, {
            prepGateway: {
                method: 'POST',
                url: endpoints.payments.prepGateway.url
            },

            acknowledge: {
                method: 'PUT',
                url: endpoints.payments.confirm.url,
                params: {
                    paymentId: '@paymentId'
                }
            }
        }),

        _newPaymentId = null,

        _newTransactionId = null,

        _acknowledgeResumeData = null,

        _step1 = function(payment, recaptchaResponse){
            var deferred = $q.defer(),
                tryPrep = function(){
                    var serializedPayment = payment.serialize();
                    serializedPayment.redirectUrl = Config.baseUrl + 'gatewayProxies/request.html?responded=true';
                    serializedPayment.recaptchaResponse = recaptchaResponse;
                    return Payments.prepGateway(serializedPayment).$promise;
                };

            Compass.capture.event('payment', 'gateway', 'step1Start');

            tryPrep().then(function(resp){
                deferred.resolve(resp);
            }).catch(function(resp){

                // if we have a network error
                // we will retry it once
                if(ErrorsService.resolve(resp) === ErrorsService.errors.NETWORK_ERROR) {

                    $log.error('Network error, could not prepGatway, trying again...');

                    Compass.capture.event('payment', 'gateway', 'step1Retry');

                    tryPrep().then(deferred.resolve, function(resp){
                        Compass.capture.failure('payment', 'gateway', 'step1Ended');
                        deferred.reject(resp);
                    });

                } else {
                    Compass.capture.failure('payment', 'gateway', 'step1Ended');
                    deferred.reject(resp);
                    return;
                }



            });

            return deferred.promise;
        },

        _step2 = function(prepResp, payment){

            var deferred = $q.defer(),
                tryPostToGateway = function(){
                    return GatewayFactoryService
                        .create(prepResp.gateway)
                        .postToGateway(prepResp, payment);
                };

            Compass.capture.event('payment', 'gateway', 'step2Start');


            tryPostToGateway().then(function(resp){
                deferred.resolve(resp);
            }).catch(function(resp){

                // if we did not have a network error,
                // pass through the error
                if(ErrorsService.resolve(resp) !== ErrorsService.errors.NETWORK_ERROR){
                    Compass.capture.failure('payment', 'gateway', 'step2Ended');
                    deferred.reject(resp);
                    return;
                }

                $log.error('Network error, could not post to gatway, trying again...');

                Compass.capture.event('payment', 'gateway', 'step2Retry');

                tryPostToGateway().then(deferred.resolve,  function(resp){
                    Compass.capture.failure('payment', 'gateway', 'step2Ended');
                    deferred.reject(resp);
                });
            });

            return deferred.promise;
        },

        _step3 = function(ackDetails){

            Compass.capture.event('payment', 'gateway', 'step3Start');

            var deferred = $q.defer(),
                tryAck = function(ackDetails){
                    return Payments.acknowledge(ackDetails).$promise;
                };

            tryAck(ackDetails).then(function(resp) {
                deferred.resolve(resp);
            }).catch(function(resp){

                // if we did not have a network error,
                // or this is attempt 2+
                // pass through the error
                if(ErrorsService.resolve(resp) !== ErrorsService.errors.NETWORK_ERROR || _acknowledgeResumeData !== null){

                    if(_acknowledgeResumeData){
                        Compass.capture.failure('payment', 'gateway', 'step3Resume');
                    }

                    Compass.capture.failure('payment', 'gateway', 'step3Ended');

                    deferred.reject(resp);
                    return;
                }

                $log.error('Network error, could not send payment acknowledgement. Request for retry');

                Compass.capture.event('payment', 'gateway', 'step3RetryRequested');

                _acknowledgeResumeData = _.cloneDeep(ackDetails);
                deferred.reject(ErrorsService.errors.GATEWAY_RETRY_REQUESTED);

            });

            return deferred.promise;
        },

        _3StepRedirect = function(payment, recaptchaResponse, promise, resuming){
            // if we are resuming we are saying that we want
            // to skip the _step1 and _step2 parts and
            // only complete _step3
            if(resuming && _acknowledgeResumeData !== null){
                Compass.capture.event('payment', 'gateway', 'step3ResumeStart');
                return _step3(_acknowledgeResumeData).then(function(){
                    Compass.capture.success('payment', 'gateway', 'step3Resume');
                });
            }

            return _step1(payment, recaptchaResponse).then(function(resp){

                if(!resp || !resp.hasData()){
                    $log.error('PrepGateway responded with no data');
                    promise.reject();
                    return;
                }

                Compass.capture.success('payment', 'gateway', 'step1Ended');

                _newPaymentId = resp.getData().paymentId;
                _newTransactionId = resp.getData().transactionId;

                $log.info('PrepGateway Responded, invoking gateway.postToGateway');


                // We are returning the correct failure,
                // though we need to put this into its own function
                // and retry once

                return _step2(resp.getData(), payment);

            }).then(function( gatewayRequestProperties ){

                Compass.capture.success('payment', 'gateway', 'step2Ended');

                $log.info('PostToGateway responded, invoke acknowledge.');

                // the ack details can contain
                // gateway specific requirements
                // those should be returned from the gateway's
                // postToGateway promise resolver
                var ackDetails = _.assign({
                    paymentId: _newPaymentId,

                    // transaction is needed because nmi wont provide
                    // a transactionId if it failed on the third step
                    // we need this for tracking that state
                    transactionId: _newTransactionId



                }, (gatewayRequestProperties || {}));


                if (payment.method === PAYMENT_METHOD.CREDIT){
                    ackDetails.method = PAYMENT_METHOD.CREDIT;
                    ackDetails.paymentForm =  {};
                    ackDetails.paymentForm.last4Digits = payment.paymentForm.cardNumber.toString().slice(-4);
                    ackDetails.ccExpiration = payment.paymentForm.expDate;
                } else if (payment.method === PAYMENT_METHOD.ECHECK) {
                    ackDetails.method = PAYMENT_METHOD.ECHECK;
                    // backend needs routing number so they don't have to parse
                    // the gateway response
                    ackDetails.routingNumber = payment.paymentForm.routingNumber;
                }

                return _step3(ackDetails);
            }).then(function( ackResp ) {
                if(!ackResp || !ackResp.hasData()){
                    $log.error('ProcessPayment responded with no data');
                    promise.reject();
                    return;
                }

                Compass.capture.success('payment', 'gateway', 'step3Ended');

                return ackResp.getData();
            });
        },

        _processPayment = function( payment, recaptchaResponse, promise, resuming ){

            if(!resuming && payment.validate['method']){
                $log.error('Unable to process payment without a payment.method');
                return;
            }

            // we we are starting a new process
            // clear the previous attempt if it existed
            if(!resuming){
                _acknowledgeResumeData = null;
            }

            $log.info('Invoking PrepGate for new form');

            if(resuming){
                Compass.capture.event('payment', 'gateway', 'resuming3Step');
            }else {
                Compass.capture.event('payment', 'gateway', 'starting3Step');
            }

            // regarding acknowledge
            _3StepRedirect(payment, recaptchaResponse, promise, resuming).then(function(finalizedPayment){
                $log.info('Acknowledge successful, payment process complete');
                promise.resolve(finalizedPayment);
            })
            .catch(function(resp){
                $log.log('Unable to process payment form');
                promise.reject(resp);
            })
            .finally(function(){
                // clean up service variables
                // if we are not expecting to need them
                // for a acknowledgement resume process
                if(_acknowledgeResumeData === null || resuming){
                    _newPaymentId = null;
                    _newTransactionId = null;
                }
            });
        },

        _resumePreviousPaymentAttempt = function(){
            var deferred = $q.defer();

            if(_acknowledgeResumeData === null){
                $log.log('Attempting to resume acknowledgement without details for it, bailing out... ');
                deferred.reject(ErrorsService.errors.UNKNOWN_ERROR);
            } else {
                _processPayment(null, null, deferred, /*resuming*/ true);
            }

            return deferred.promise;
        };


    return {

        // Called when we want to submit a process a payment
        // It will decide what form type to process the payment
        // as and will handle retries internally.
        process: function( payment, recaptchaResponse ){

            // if new form
            //      prepGateway -- send a prepGateway insert
            //                  -- we get back the gateway info
            //      for the gateway we were given
            //          POST to their action with the app
            //              processing the response is gateway specific
            //      if successful POST response
            //          sendUpdate for this payment
            // if saved form
            //      sendInsert for this paymentFormId

            var deferred = $q.defer();

            // validate we have an interanally created payment
            // object to process on.
            if( !payment ){

                $log.error('GatewayService was passed an invalid payment object');
                deferred.reject(null);

            } else {
                _processPayment(payment, recaptchaResponse, deferred);
            }

            return deferred.promise;
        },

        // When we call this#process if it gets to the confirmation
        // step it will request that the user manually resubmit the form
        // this is to buy us some time between NMI being ready and
        // our server call
        resume: function(){
            return _resumePreviousPaymentAttempt();
        }
    };
});
